import { Byte } from "@angular/compiler/src/util";
import { Guid } from "guid-typescript";
import { DateTimeOffset } from "datetime-offset";

export class 
RegisterModel {
  id?: string;  
  accessFailedCount?: number; 
  address?: string; 
  chargePerRate?: number | null;
  concurrencyStamp?: string;
  contact_No?: string;
  costPerHour?: number | null;
  created_By?: number | null;
  created_Date?: Date | null;
  email?: string; 
  emailConfirmed?: boolean;
  employeeId?: Guid | null;
  isActive?: boolean | null;
  isDelete?: boolean | null;
  lockoutEnabled?: boolean;
  lockoutEnd?: DateTimeOffset | null;
  modified_By?: number | null;
  modified_Date?: Date | null;
  name?: string;
  normalizedEmail?: string;
  normalizedUserName?: string;
  organization_Id?: Guid | null;
  password?: string;
  passwordHash?: string;
  phoneNumber?: string;
  phoneNumberConfirmed?: boolean;
  role?: Byte | null;
  securityStamp?: string;
  twoFactorEnabled?: boolean;
  userName?: string;
  user_Status?: Byte | null;
  user_Id?: number | null;
  organisationIDs?: string;
  chkEmail?: boolean;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}   

// export class RegisterModel {
//   id: string;  
//   accessFailedCount: number; 
//   address: string; 
//   chargePerRate?: number;
//   concurrencyStamp: string;
//   contact_No: string;
//   costPerHour?: number;
//   created_By?: number;
//   created_Date?: Date;
//   email: string; 
//   emailConfirmed: boolean;
//   employeeId?: Guid;
//   isActive?: boolean;
//   isDelete?: boolean;
//   lockoutEnabled: boolean;
//   lockoutEnd?: DateTimeOffset;
//   modified_By?: number;
//   modified_Date?: Date;
//   name: string;
//   normalizedEmail: string;
//   normalizedUserName: string;
//   organization_Id?: Guid;
//   password: string;
//   passwordHash: string;
//   phoneNumber: string;
//   phoneNumberConfirmed: boolean;
//   role?: Byte;
//   securityStamp: string;
//   twoFactorEnabled: boolean;
//   userName: string;
//   user_Status?: Byte;
//   user_Id?: number;
//   organisationIDs: string;
//   chkEmail: boolean;
//   firstName: string;
//   lastName: string;
//   companyName: string;
// }        

// export class RegisterModel {
//   email: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   companyName: string;
// }

export class CurrentUserModel {
  id?: string;    
  address?: string;     
  contact_No?: string;  
  created_By?: number | null;
  created_Date?: Date | null;
  email?: string;     
  isActive?: boolean | null;
  isDelete?: boolean | null;  
  modified_By?: number | null;
  modified_Date?: Date | null;
  name?: string;
  normalizedEmail?: string;
  normalizedUserName?: string;
  organizationId?: Guid | null;  
  phoneNumber?: string;
  phoneNumberConfirmed?: boolean;
  role?: Byte | null;  
  twoFactorEnabled?: boolean;
  userName?: string;
  user_Status?: Byte | null;
  userId?: number | null;
  organizationIds: string;
  chkEmail?: boolean;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}   